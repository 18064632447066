<template>
  <div class="anb-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/anb">ANB transactions</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.booking_number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3 d-flex">
        <div class="d-flex align-center" v-if="data.customer">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.customer.picture != null" :src="data.customer.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <div class="text-h6 font-weight-bold">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
            <div v-if="role != 'manager'">
              <router-link :to="`/customers/${data.customer.id}`">Open profile</router-link>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-card flat width="140" height="42" class="status-block" :class="getColor(data.status)">{{ data.status }}</v-card>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">{{ data.booking_number }}</div>
        <div class="mb-3">
          <span class="text--text">Transaction date:</span>
          {{
            new Date(data.created_at).toLocaleString('en-GB', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          }}
        </div>
        <v-card flat class="width100" max-width="400">
          <div class="font-weight-bold">Transaction ID</div>
          <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
            <div class="text--text text-truncate">{{ data.transaction_id }}</div>
          </v-card>
          <!-- <div class="font-weight-bold mt-5">Branch</div>
          <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
            <div class="text--text text-truncate">{{ data.branch }}</div>
          </v-card> -->
          <div class="font-weight-bold mt-5">Merchant</div>
          <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
            <div class="text--text text-truncate">{{ data.merchant }}</div>
          </v-card>
          <div class="d-flex flex-md-row flex-column">
            <div class="me-md-3 width50">
              <div class="font-weight-bold mt-5">Points amount</div>
              <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
                <div class="text--text text-truncate">{{ data.points_amount }}</div>
              </v-card>
            </div>
            <div class="ms-md-3 width50">
              <div class="font-weight-bold mt-5">Amount, SAR</div>
              <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
                <div class="text--text text-truncate">{{ data.amount / 100 }}</div>
              </v-card>
            </div>
          </div>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  mounted() {
    this.$store.dispatch('getAnbItem');
  },
  methods: {
    getColor(s) {
      if (s == 'redeemed') {
        return 'success--text success lighten-5';
      } else if (s == 'expired' || s == 'refunded') {
        return 'error--text error lighten-5';
      } else if (s == 'authorization') {
        return 'primary--text primary lighten-3';
      } else {
        return 'gray--text gray lighten-3';
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.anb;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setAnbItem');
  },
};
</script>

<style lang="scss">
.anb-item {
  .status-block {
    font-weight: 700;
    line-height: 42px !important;
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
